import React from "react";
import Link from '@mui/material/Link';

const MainDescription = () => {

    return (
      <React.Fragment>
        <div className="info-area">
          <div className="container">
            {/* <h2>About VariXam</h2>  */}
            <div className="main-text">
              <p><span className="brand">VariXam</span> is an aggregated database and a variant browser that shows genomic variants detected on whole-genome/whole-exome sequence (WGS/WES) data of the <Link href="https://www.niagads.org/adsp/content/home">Alzheimer's Disease Sequencing Project (ADSP)</Link>. The variants were processed by <Link href="https://www.niagads.org/resources/tools-and-software/vcpa">VCPA (Variant Calling Pipeline and data management tool)</Link> and <Link href="https://dss.niagads.org/datasets/ng00067/">released by NIAGADS</Link>. The database currently includes variants of R1 5K WGS, R2 20K WES, R3 17K WGS, and R4 36K WGS. The human reference genome used is GRCh38.</p>
              <p>ADSP and NIAGADS are sponsored by the National Aging Institute (NIA) and aim to improve our understanding and provide insight of genetic factors of Alzheimer's Disease.</p>
            </div>
          </div>
        </div>

      </React.Fragment>

    )
  };

export default MainDescription;
