import React from "react";
import Link from '@mui/material/Link';



const Footer = () => {

    return (
      <React.Fragment>
        <footer className='site-footer'>
          <div className="container">
            <p>&copy; {new Date().getFullYear()} University of Pennsylvania, School of Medicine. All rights reserved.</p>
          </div>
        </footer>
      </React.Fragment>

    )
  };

export default Footer;
