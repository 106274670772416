import React, {useState, useEffect, useMemo} from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter,
  } from '@mui/x-data-grid';
import { Box, Link } from '@mui/material';

function CustomToolbar() {
    return (
      <React.Fragment> 
        <Box
         sx={{
            display: 'flex',
            justifyContent: 'space-between',
            m: 1,
            
          }}
        >
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                {/* <GridToolbarFilterButton /> */}
                <GridToolbarExport />
            </GridToolbarContainer>
            <GridToolbarQuickFilter />

        </Box>
      </React.Fragment>
    );
  }

const columns = [
  // { field: 'id', headerName: 'ID', width: 70, headerClassName: 'variants-header', },
  { field: 'chr', headerName: 'Chromosome', minWidth: 120, flex: 1, headerClassName: 'variants-header'},
  { field: 'pos', headerName: 'Position', minWidth: 120, flex: 1, headerClassName: 'variants-header'},
  {
    field: 'ref',
    headerName: 'Ref Allele',
    minWidth: 120,
    flex: 1,
    headerClassName: 'variants-header',
  },
  {
    field: 'alt',
    headerName: 'Alt Allele',
    minWidth: 120,
    flex: 1,
    headerClassName: 'variants-header',
  },
  {
    field: 'dataset_name',
    headerName: 'Project',
    minWidth: 120,
    flex: 1,
    headerClassName: 'variants-header',
  },
  {
    field: 'filter',
    headerName: 'Filter',
    minWidth: 120,
    flex: 1,
    headerClassName: 'variants-header',
  },
  {
    field: 'multiallelic',
    headerName: 'Multiallelic',
    minWidth: 120,
    flex: 1,
    headerClassName: 'variants-header',
  },
  {
    field: 's_id',
    headerName: 'rsID',
    minWidth: 120,
    flex: 1,
    headerClassName: 'variants-header',
    renderCell: (params) => (
          <Link
            href={`https://www.ncbi.nlm.nih.gov/snp/${params.value}`}
            variant="string"
            target="_blank"
            rel="noopener"
          >
            {params.value}
        </Link>
      ),
  },
];


export const Variants = ({variants, rowCount, loading}) => {

    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(50);
    
    const [rowCountState, setRowCountState] = useState(rowCount);
    useEffect(() => {
      setRowCountState((prevRowCountState) =>
        rowCount !== undefined ? rowCount : prevRowCountState,
      );
    }, [rowCount, setRowCountState]);
    const queryOptions = React.useMemo(
      () => ({
        page,
        pageSize,
      }),
      [page, pageSize],
    );

    const rows = variants;


    return (
        <React.Fragment>
           
            <Box 
                sx={{
                height: 500, 
                width: '100%',
                '& .variants-header': {
                    backgroundColor: 'var(--dss-light_yellow)',
                    // color: '#ffffff',
                },
                }}
            >
                <DataGrid
                    disableColumnMenu
                    // autoHeight
                    // rowCount={rowCountState}
                    disableSelectionOnClick
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    // page={page}
                    // paginationMode="server"
                    // onPageChange={(newPage) => setPage(newPage)}
                    components={{ Toolbar: CustomToolbar }}
                    // componentsProps={{
                    //     toolbar: {
                    //       showQuickFilter: true,
                    //       quickFilterProps: { debounceMs: 500 },
                    //     },
                    //   }}
                    density="compact"

                />
            </Box>
           
        </React.Fragment>
    )
}