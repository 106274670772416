import React from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';



const SearchForm = ({handleSubmit, searchQuery, setSearchQuery, handleFocus, error}) => {

    return (
      <React.Fragment>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          id="search-bar"
          className="text"
          onInput={(e) => {
            setSearchQuery(e.target.value);
          }}
          onFocus={handleFocus}
          label="Enter the region, gene or rsID and click the search icon to see autosomal variants"
          variant="outlined"
          value={searchQuery}
          placeholder="Search..."
          size="normal"
          InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSubmit}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
        />
          
        {/* <IconButton type="submit" aria-label="search">
          <SearchIcon style={{ fill: "blue" }} />
        </IconButton> */}
      </form>
      <div className="error">{error}</div>
      <p className="note examples">
          Examples - &nbsp;
          Gene: <Link component="button" variant="string" onClick={(e)=> {setSearchQuery('APOE')}}>
            APOE
          </Link>&nbsp; - &nbsp; 
          Chromosome Position: <Link component="button" variant="string" onClick={(e)=> {setSearchQuery('5:17100035')}}>
          5:17100035
          </Link>&nbsp; - &nbsp; 
          Chromosome Region: <Link component="button" variant="string" onClick={(e)=> {setSearchQuery('19:1593000-1594000')}}>
          19:1593000-1594000
          </Link>&nbsp; - &nbsp;  
          rsID: <Link component="button" variant="string" onClick={(e)=> {setSearchQuery('rs146621479')}}>
          rs146621479
          </Link>
      </p>
      </React.Fragment>

    )
  };

export default SearchForm;
