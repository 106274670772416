import React from "react";

const ApiText = () => {

    return (
      <React.Fragment>
          <div className="container">
            {/* <h2>About VariXam</h2>  */}
            <div className="text">
                <p>Apart from the web interface, the contents of the VariXam database can also be accessed using an API through the command line. It uses JSON objects to send and receive the variants data. Example usage for reference here:
                </p>
                <div className="text-section">
                    <span>Search by Gene Name:</span>
                    <code>curl -X POST -H "Content-type: application/json" -d "&#123;\"gene_name\" : \"SNORD29\"&#125;" https://varixam.niagads.org/post_json</code>
                </div>
                <div className="text-section">
                    <span>Search by Chromosome, Position:</span>
                    <code>curl -X POST -H "Content-type: application/json" -d "&#123;\"chromosome\" : \"19\", \"position\" : \"44908684\"&#125;" https://varixam.niagads.org/post_json</code>
                </div>
                <div className="text-section">
                    <span>Search by Region (Chromosome, Start, End):</span>
                    <code>curl -X POST -H "Content-type: application/json" -d "&#123;\"chromosome\" : \"19\", \"start\" : \"1593100\", \"end\" : \"1593200\"&#125;" https://varixam.niagads.org/post_json</code>
                </div>
                <div className="text-section">
                    <span>Search by rsID:</span>
                    <code>curl -X POST -H "Content-type: application/json" -d "&#123;\"rsid\" : \"rs429358\"&#125;" https://varixam.niagads.org/post_json</code>
                </div>

            </div>
          </div>

      </React.Fragment>

    )
  };

export default ApiText;
