import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback } from 'react'
import { createPortal } from 'react-dom'

const modalElement = document.getElementById('modal-root')

const Modal = ({ children, fade = false, defaultOpened = false, title }, ref) => {
  const [isOpen, setIsOpen] = useState(defaultOpened)

  const close = useCallback(() => setIsOpen(false), [])

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close
  }), [close])

  const handleEscape = useCallback(event => {
    if (event.keyCode === 27) close()
  }, [close])

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handleEscape, false)
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [handleEscape, isOpen])

  return createPortal(
    isOpen ? (
      <div className={`modal ${fade ? 'modal-fade' : ''}`}>
        <div className="modal-overlay" onClick={close}></div>
          <div className="modal-body">
              <div className="container">
                <div className="close" onClick={close}><span>x</span></div>
                <div className='panel'>
                  <div className="panel-title-area"><h2 className="form-bg-title">{title}</h2></div>
                  {children}
                </div> 
              </div>
          </div>
      </div>
    ) : null,
    modalElement
  )
}

export default forwardRef(Modal)