import React, {useEffect, useState, useRef} from 'react';
import './App.css';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import logo from './varixam.svg';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { alpha } from '@mui/material/styles';
import SearchForm from './components/SearchForm';
import { Variants } from './components/Variants';
import MainDescription from './components/MainDescription';
import Modal from './components/Modal';
import ApiText from './components/ApiText';
import Loader from './components/Loader';
import Footer from './components/Footer';

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: '#618eb5' ,
      light:'#62829e' ,
      dark: '#28333f' ,
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#FFC665',
      main: '#FFC665',
      dark: '#F1B154',
      contrastText: '#28333f',
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function App() {

  const [searchQuery, setSearchQuery] = useState('');
  const [searchJson, setSearchJson] = useState(searchQuery);
  const [searchTitle, setSearchTitle] = useState('');
  const [variants, setVariants] = useState([]);
  const [rowCount, setRowCount] = useState();
  const [loading, setLoading] = useState(false);
  // const [validSearch, setValidSearch ] = useState(false);
  const [searchError, setSearchError ] = useState('');
  const [jsonError, setJsonError ] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const apiModal = useRef(null)

  useEffect(()=>{
    console.log(searchJson)
    if(!searchJson.error){
      setSearchError('');
    } else {
      setSearchError('Incorrect input. Please see examples search below.');

    }
  }, [searchJson])

  const patternLetters = /^[a-zA-Z]/;
  const patternChr = /[0-9\:\-]/;
  const patternNum = /^\d+$/;
  const patternNumTextHyphen = /^[A-Za-z0-9\-]+$/;

  useEffect(()=>{
    console.log('searchJson: ', searchJson)
    let userInput = searchQuery.replace(/\s/g,'');
    if (userInput.startsWith('rs') && patternNum.test(userInput.slice(2))){
      // setSearchError('');
      setSearchJson({
        "rsid": userInput
      })
      console.log(searchJson)
    } else if (patternLetters.test(userInput[0]) && patternNumTextHyphen.test(userInput)) {
      // setSearchError('');
      setSearchJson({
        "gene_name": userInput
      })
      console.log(searchJson)
    } else if (patternChr.test(userInput)){
      let temp = userInput.split(/[:-]+/)
      if (temp.length === 2) {
        // setSearchError('');
        setSearchJson({
          "chromosome": temp[0],
          "position": temp[1]
        })  
        console.log(searchJson)
    
      } else if (temp.length === 3) {
        // setSearchError('');
        setSearchJson({
          "chromosome": temp[0],
          "start": temp[1],
          "end": temp[2]
        })      
        console.log(searchJson)

      } else {
        setSearchJson({
          "error": true
        })   
      }
    } else {
      setSearchJson({
        "error": true
      })   
    }
    if(userInput == ''){
      setSearchJson('')
    }
  }, [searchQuery])

  

  const handleFocus = () => {
    if(!searchJson.error){
      setSearchError('');
    } else {
      setSearchError('Incorrect input. Please see examples search below.');

    }
  }

  const validate = () => {
    let searchError = '';
    console.log('searchJson: ', searchJson)
    if (searchJson.error || searchJson == ''){
      searchError = 'Incorrect input. Please see examples search below.';
    }
    if(searchError){
      setSearchError(searchError);
      return false;
    }
    return true;

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validate();

    if (isValid){
      setIsLoading(true);
      setLoading(true);
      fetch('/post_json', {
        method: 'POST',
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         },
        body: JSON.stringify(searchJson),
      })
      .then(response => response.json())
      .then(data => {
        console.log('data: ', data)
        setVariants(data.data);
        setRowCount(data.row_count);
        setLoading(false);
        setSearchTitle(searchQuery);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setJsonError('Search error or no data.');
      });
    }

    setJsonError('');
    setVariants([]);
  }
  
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <header id="masthead" className="site-header">
    
        <div className="container-wide flex justify-content-space-between align-items-center gap-1">
            <div className="site-branding">
                <div className="logo">
                <img src={logo} className="varixam-logo" alt="varixam logo" />	
                </div>
            </div>
            <nav className="main-navigation"><ul><li onClick={() => apiModal.current.open()}>API</li></ul></nav>

        </div>
      </header>   

      <main>
      <MainDescription />

       <div className="container">
         <Container maxWidth="xl">
             <div className="main-area">
               <div className="search-form-area">
                <SearchForm handleSubmit={handleSubmit} handleFocus={handleFocus} searchQuery={searchQuery} setSearchQuery={setSearchQuery} error={searchError} />
               </div>

               {jsonError}
               {isLoading ? <Loader /> : ''}
               {variants && variants.length > 0 &&
                <div className="results-area">
                  <h3 className="search-title">Showing results for: <span className="search-query">{searchTitle}</span></h3>
                  <Variants rowCount={rowCount} variants={variants} loading={loading}/>
                </div>
                } 
             </div>
          </Container>     
        </div>    
     </main>
     <Footer />           
    </div>
      <Modal  ref={apiModal} >
          <ApiText />                 
      </Modal>
    </ThemeProvider>  
  );
}

export default App;
